import React from 'react';
import { graphql } from 'gatsby';
import { Query } from 'react-apollo';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import gql from 'graphql-tag';
import logger from 'utils/logger';
import Fade from 'react-reveal/Fade';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Menu } from 'components/Navigation';
import Layout from 'components/Layout';
import SEO from 'components/seo';

import { Container, Row } from 'components/GridSystem';
import { CallToActionPreFooter } from 'components/CallToActions';
import {
  // BusinessFunction,
  SectionLabel,
  SectionTitle
} from 'components/Text';
import { CapabilityCard } from 'components/Cards';

import { Button } from 'components/Elements';

import {
  ApproachDescription,
  CoverApproach,
  IntroService,
  TextIntro,
  BusinessFunction,
  SectionIntro,
  ApproachHighlight,
  ApproachCard
} from 'components/Sections';

const AreasIntro = styled.div`
  ${tw`
    pt-6
    pb-4
    border-0
    border-t-2
    border-solid
    border-gray-200
    xl:px-1/24
    xl:mx-1/24
  `};
  h1 {
    ${tw`
      font-serif
      tracking-tight
    `};
  }
  p {
    ${tw`
      font-light
      tracking-tight
      leading-fit
      text-gray-400
      xl:w-1/2
      xl:text-2xl
    `};
  }
`;
class Page extends React.Component {
  render () {
    const {
      data,
      location
    } = this.props;
    logger.gql(data);
    logger.template(__filename, '>', 'render()');
    const siteTitle = data.site.siteMetadata.title;
    const approachAreas = data.allContentfulApproach.edges;
    const {
      title,
      description,
      heading,
      slug,
      fields
    } = data.contentfulPage;
    const { blocks } = fields;

    const seoProps = {
      title,
      description: description && description.childMdx.rawBody
    };
    const animationDuration = 600;
    logger.templateEnd();
    return (
      <Layout location={location} title={siteTitle}>
        <SEO {... seoProps} />
        <Menu colorScheme={`dark`} />
        <Fade>
          <IntroService
            bgColor={`gray-800`}
            label={title}
            intro={blocks[0].titleRich && blocks[0].titleRich.childMdx.rawBody}
            content={blocks[0].content && blocks[0].content.childMdx.body}
          />
          <article>
            {title && (
              <TextIntro
                content={blocks[1].titleRich && blocks[1].titleRich.childMdx.rawBody}
              />
            )}
            <ApproachDescription>
              <MDXRenderer>
                {blocks[1].content && blocks[1].content.childMdx.body}
              </MDXRenderer>
            </ApproachDescription>
            <section>
              <Container fluid>
                <Row>
                  <AreasIntro>
                    <h1>{blocks[2].titleRich && blocks[2].titleRich.childMdx.rawBody}</h1>
                    <div>
                      <MDXRenderer>
                        {blocks[2].content && blocks[2].content.childMdx.body}
                      </MDXRenderer>
                    </div>
                  </AreasIntro>
                </Row>
              </Container>
            </section>
            {/*
              */}
            {approachAreas && <ApproachCard approachAreas={approachAreas} />}
            {/*
            <section>
              <Container>
                <Row>
                  <Fade left duration={animationDuration} distance={`2%`} delay={animationDuration}>
                    <SectionLabel label={`blocks[2].preTitle && blocks[2].preTitle`} />
                  </Fade>
                </Row>
                <Row>
                  <Fade left duration={animationDuration} distance={`10%`} delay={animationDuration / 2}>
                    <SectionIntro />
                  </Fade>
                </Row>
              </Container>
              */}
             
             {/*
              { approachAreas && (
                approachAreas.length > 0 && approachAreas.map((edge, i) => {
                  const { node } = edge;
                  const props = {
                    title: node.title,
                    animationDuration,
                    animationDelay: (i > 2 ? i - 2.5 : i) * 200,
                    src: `https://www.mckinsey.com/~/media/McKinsey/Business%20Functions/McKinsey%20Design/How%20we%20help%20clients/design_thumb-blue_491763594_1536x1536.ashx?mw=767&car=42:25`
                  };
                  const {
                    serviceActivities
                  } = node;
                  return (
                    <ApproachHighlight
                      key={node.id}
                      label={node.title}
                      bgColor={`gray-100`}
                      border={i !== approachAreas.length - 1}
                      {...node}
                    >
                      <div key={node.id}>
                        <p>
                          We can help you reinvent the way you deliver and pilot your services to be more beneficial, accessible and desirable for your users, as well as more efficient and sustainable for your organization — increasing productivity, commercial value and customer satisfaction
                        </p>
                        {serviceActivities && (
                          <div>
                            { serviceActivities.length > 0 && (
                              <ul>
                                {serviceActivities.map((subnode) => {
                                  logger.debug(`subnode`, subnode);
                                  return (
                                    <li key={subnode.id}>{subnode.title}</li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                         )}
                        <Button
                          label={`Read More`}
                          color={`brand-primary`}
                          textColor={`gray-500`}
                          size={`md`}
                          outline
                          uppercase
                          lighten
                          navigateTo={`/${slug}/${node.slug}`}
                        />
                      </div>
                    </ApproachHighlight>
                  );
                })
              )}
            </section>
             */}
            <CallToActionPreFooter
              bgColor={`gray-700`}
              title={`Have a challenge you’d like us to explore?`}
              callToAction={`Get in touch!`}
              uri={``}
              to={`/contact`}
              utm={{
                campaign: `t`
              }}
            />
          </article>
        </Fade>
      </Layout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage( slug: { eq: $slug } ){
      ...Page
    }
    allContentfulApproach ( sort: { fields: sort, order: ASC} ){
      edges{
        node{
          ...Approach
        }
      }
    }
  }
`;
